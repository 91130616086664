import React from "react"
import Layout from "../components/layout"
import { Clients, LeadershipTeam, TwoColumns, Seo } from "../components"
import { Colors } from "../theme/styles"

const About = () => {
  return (
    <Layout title="About CheshTech" type="About">
      <Seo
        title="About the CheshTech Team"
        description="Get to know the CheshTech team! Our leadership team specializes in creating websites with modern technology, conversion rate optimization, and digital marketing."
      />
      <TwoColumns
        type="about"
        title="Working Directly With You"
        paragraph1="We appreciate you showing some interest in learning about the CheshTech team! 
        We're comprised of passionate individuals who thrive in providing quality services to our clients who are working to impact the world in a positive way. 
        Our team specializes in developing websites with the most modern technology to help you optimize performance, security, and generate new business from your website."
        paragraph2="It's our focus to work directly with you to ensure you're getting the most out of your website and your digital marketing strategy, including social media marketing, podcast and show production, and email marketing. It all ends with your website which should be built as a 24/7 sales machine to generate new leads and customers for your business. Interesting in partnering with an agency who shares your same mission and values? Contact us today!"
      />
      <LeadershipTeam background={Colors.lightBlue} color={Colors.white} />
      <Clients />
    </Layout>
  )
}

export default About
